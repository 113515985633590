<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <IconClassBar
          title="Präsenzkontrolle"
          :marks="true"
          :comments="true"
          :presence="true"
          current="presence"
          v-on:edit-mode="editMode=true"
        />
        <v-card-text>
          <v-row
            align="start"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="6"
              sm="5"
            >
              <v-select
                :items="subjects"
                filled
                label="Fach"
                dense
                outlined
                color="success"
                class="mt-3"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer />
            <v-col
              class="d-flex"
              cols="4"
              sm="5"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="desserts"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
          >
            <template v-slot:item.name="{item}">
              <div class="d-flex align-center">
                <v-icon :color="item.alarm">mdi-lightbulb</v-icon>
                <v-avatar
                  class="ml-1 mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="item.img"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.action="{item}">
              <v-btn
                v-if="item.action === 'Active'"
                class="mx-2"
                fab
                outlined
                x-small
                color="success"
                @click="item.action = 'Deactive'"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                v-else
                class="mx-2"
                fab
                outlined
                x-small
                color="danger"
                @click="item.action = 'Active'"
              >
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.badge="{item}">
                  <v-btn
                    v-if="item.badge === 'Active'"
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="success"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                
              
                  <v-btn
                    v-else
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="danger"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                
            </template>
            <template v-slot:item.calories="{item}">
              
                  <v-btn
                    v-if="item.calories === 'Active'"
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="success"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                
              
                  <v-btn
                    v-else
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="danger"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                
            </template>
            <template v-slot:item.fat="{item}">
              
                  <v-btn
                    v-if="item.fat === 'Active'"
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="success"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                
              
                  <v-btn
                    v-else
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="danger"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                
            </template>
            <template v-slot:item.carbs="{item}">
              
                  <v-btn
                    v-if="item.carbs === 'Active'"
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="success"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                
              
                  <v-btn
                    v-else
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="danger"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                
            </template>
            <template v-slot:item.protein="{item}">
              
                  <v-btn
                    v-if="item.protein === 'Active'"
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="success"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                
              
                  <v-btn
                    v-else
                    class="mx-2"
                    fab
                    outlined
                    x-small
                    color="danger"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                
            </template>
            <template v-slot:footer>
              <div style="position: absolute" class="pl-2 pt-2">
                <v-chip-group
                  column
                >
                  <v-chip color="red" outlined>
                    <v-icon left>
                      mdi-lightbulb
                    </v-icon>
                    unzureichende Präsenz
                  </v-chip>
                  <v-chip color="orange" outlined>
                    <v-icon left>
                      mdi-lightbulb
                    </v-icon>
                    achtung
                  </v-chip>
                  <v-chip color="green" outlined>
                    <v-icon left>
                      mdi-lightbulb
                    </v-icon>
                    mehr als 90% anwesend
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue';
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Lernende',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Lektion 1', value: 'calories' },
          { text: 'Lektion 2', value: 'fat' },
          { text: 'Lektion 3', value: 'carbs' },
          { text: 'Lektion 4', value: 'protein' },
          { text: 'Lektion 5', value: 'badge' },
          { text: 'Lektion 6', value: 'action' },
        ],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        desserts: [
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            alarm: 'green',
            name: 'Jhon Doe',
            calories: 'Active',
            fat: 'Deactive',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
            action: 'Active',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            alarm: 'green',
            name: 'Elizabeth Doe',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
            action: 'Active',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            alarm: 'green',
            name: 'Jhon Legend',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Deactive',
            protein: 'Active',
            badge: 'Active',
            action: 'Active',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            alarm: 'red',
            name: 'Juggernaut',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
            action: 'Active',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            alarm: 'green',
            name: 'Medusa',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            alarm: 'orange',
            name: 'Jhon Doe two',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            alarm: 'green',
            name: 'Elizabeth Doe two',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Deactive',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            alarm: 'red',
            name: 'Jhon Legend two',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            alarm: 'green',
            name: 'Juggernaut two',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            alarm: 'orange',
            name: 'Medusa two',
            calories: 'Deactive',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            alarm: 'orange',
            name: 'Elizabeth Doe three',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            alarm: 'green',
            name: 'Jhon Legend three',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            alarm: 'green',
            name: 'Juggernaut three',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            alarm: 'green',
            name: 'Medusa three',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            alarm: 'green',
            name: 'Jhon Doe four',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            alarm: 'green',
            name: 'Elizabeth Doe four',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            alarm: 'green',
            name: 'Jhon Legend four',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Deactive',
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            alarm: 'green',
            name: 'Juggernaut four',
            calories: 'Active',
            fat: 'Active',
            carbs: 'Active',
            protein: 'Active',
            badge: 'Active',
          },
        ],
        subjects: [
          'Administration',
          'Wirtschaft und Gesellschaft',
        ]
      }
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
