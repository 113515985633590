var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('IconClassBar',{attrs:{"title":"Präsenzkontrolle","marks":true,"comments":true,"presence":true,"current":"presence"},on:{"edit-mode":function($event){_vm.editMode=true}}}),_c('v-card-text',[_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"5"}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.subjects,"filled":"","label":"Fach","dense":"","outlined":"","color":"success"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","sm":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.desserts,"item-key":"name","multi-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":item.alarm}},[_vm._v("mdi-lightbulb")]),_c('v-avatar',{staticClass:"ml-1 mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.action === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"},on:{"click":function($event){item.action = 'Deactive'}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"},on:{"click":function($event){item.action = 'Active'}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return [(item.badge === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [(item.calories === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.fat",fn:function(ref){
var item = ref.item;
return [(item.fat === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.carbs",fn:function(ref){
var item = ref.item;
return [(item.carbs === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.protein",fn:function(ref){
var item = ref.item;
return [(item.protein === 'Active')?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-check")])],1):_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","x-small":"","color":"danger"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pl-2 pt-2",staticStyle:{"position":"absolute"}},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-chip',{attrs:{"color":"red","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lightbulb ")]),_vm._v(" unzureichende Präsenz ")],1),_c('v-chip',{attrs:{"color":"orange","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lightbulb ")]),_vm._v(" achtung ")],1),_c('v-chip',{attrs:{"color":"green","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lightbulb ")]),_vm._v(" mehr als 90% anwesend ")],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }